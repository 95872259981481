import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  notifications: [],
  status: "idle",
  assignedNumber: null,
  statusOpenedReachedTodos: "idle",
  error: null,
  unreadNotificationsNumber: null,
  page: 1,
  totalPagesForNotifications: null,
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/notifications/me?page=${body?.page}&limit=10`
      );
      data = response?.data;
      if (response.status === 200) {
        return {
          data: data?.data,
          totalPages: data?.meta?.totalPages,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getUnreadNotificationsNumber = createAsyncThunk(
  "notifications/getUnreadNotificationsNumber",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/notifications/unread-number`
      );
      data = response?.data?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOpenedReachedTodos = createAsyncThunk(
  "notifications/getOpenedReachedTodos",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/to-do-list/assigned-number`
      );
      data = response?.data?.data?.assignedNumber;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const markOneNotificationAsRead = createAsyncThunk(
  "notifications/markOneNotificationAsRead",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(
        `/websites/${body.websiteID}/notifications/${body.notificationID}/mark-as-read`
      );
      data = response?.data?.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getUnreadNotificationsNumber(body.websiteID));
        return body.notificationID;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const markAllNotificationsAsRead = createAsyncThunk(
  "notifications/markAllNotificationsAsRead",
  async (websiteID, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(
        `/websites/${websiteID}/notifications/mark-all-as-read`
      );
      data = response?.data?.data;

      if (response.status === 200) {
        thunkAPI.dispatch(getUnreadNotificationsNumber(websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    receiveNotificationSuccess: (state, action) => {
      state.notifications.length > 0 &&
        (state.notifications = [action.payload, ...state.notifications]);
      state.unreadNotificationsNumber = state.unreadNotificationsNumber + 1;
    },
    incrementNotificationpageNumberSuccess: (state) => {
      state.page = state.page + 1;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state, action) => {
      action.meta.arg.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.status = "success";
      state.notifications = state.notifications.concat(action.payload.data);
      state.totalPagesForNotifications = action.payload.totalPages;
    },
    [getNotifications.rejected]: (state) => {
      state.status = "failed";
    },
    [getOpenedReachedTodos.pending]: (state) => {
      state.statusOpenedReachedTodos = "loading";
    },
    [getOpenedReachedTodos.fulfilled]: (state, action) => {
      state.statusOpenedReachedTodos = "success";
      state.assignedNumber = action.payload;
    },
    [getOpenedReachedTodos.rejected]: (state) => {
      state.statusOpenedReachedTodos = "failed";
    },
    [getUnreadNotificationsNumber.fulfilled]: (state, action) => {
      state.unreadNotificationsNumber = action.payload;
    },
    [markOneNotificationAsRead.fulfilled]: (state, action) => {
      state.notifications = state.notifications.map((notification) =>
        notification?._id === action.payload
          ? { ...notification, seen: true }
          : notification
      );
    },
    [markAllNotificationsAsRead.fulfilled]: (state) => {
      state.notifications = state.notifications.map((notification) => {
        return { ...notification, seen: true };
      });
    },
  },
});
export const reducer = slice.reducer;
export const {
  receiveNotificationSuccess,
  incrementNotificationpageNumberSuccess,
  incrementUnreadNotificationsNumberSuccess,
} = slice.actions;
export default slice;

export const receiveNotification = (data) => async (dispatch) => {
  try {
    dispatch(receiveNotificationSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const incrementNotificationpageNumber = () => async (dispatch) => {
  try {
    dispatch(incrementNotificationpageNumberSuccess());
  } catch (error) {
    return console.error(error.message);
  }
};
